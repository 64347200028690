import { IonList, IonButton, IonContent, IonPage, IonItemDivider, IonProgressBar, } from '@ionic/react';
import React, { useState } from 'react';
import Toolbar from '../components/Toolbar'
import { gql, } from '@apollo/client';
import { aquilaFieldColors, AquilaItem } from '../components/AquilaItem';
import { useSubscriptionWithElevatedRole } from '../hooks/hasuraHooks';
import { ComponentGroup, groupComponents } from '../components/ComponentGroup';


const Aquilas: React.FC = () => {
  const [showArchives, setShowArchives] = useState(false)
  const [groupByVariant, setGroupByVariant] = useState(false)
  const where = showArchives ? {} : {obsolete: {_eq: false}}  // Hide obsoletes if showArchives is false

  const { loading, data } = useSubscriptionWithElevatedRole(gql`subscription GetAllAquilas($where: aquilas_bool_exp!) {
      aquilas (order_by: {id: asc}, where: $where) {
        id
        updated_at
        computer {
          hardware_type
          jetpack_version
          nvme_gb
          ram_gb
        }
        camera_type
        camera_mac
        camera_is_flipped

        talpa_can_buses
        lidar_version
        disable_lidar
        has_wide_view
        has_central_trigger
        has_fullres
        ethernet_interface_to_robot
        electronics_version
        light_controller_firmware
        mount
        housing
        connector_plug

        description
        obsolete
        aquila_configs(order_by: {created_at: desc}, limit: 1) {
          standalone
          robot_config {
            obsolete
            taurus_configs(order_by: {id: desc}, limit: 1) {
              taurus{
                id
              }
            }
            id
          }
        }

        calibrations(order_by: {created_at: desc}, limit: 1) {
          created_at
          data
          info
        }

      }
  }`, {variables: {where}});
  const groupedComponents = groupComponents(data?.aquilas,
    ["id", "updated_at", "computer", "camera_mac", "description", "obsolete", "calibrations", "aquila_configs", "__typename"])
  return (
    <IonPage>
      <Toolbar name="Aquilas" buttons={<>
        <IonButton fill="solid" onClick={() => setShowArchives(current => !current)}>{showArchives ? 'hide archived': 'show archived'}</IonButton>
        <IonButton fill="solid" onClick={() => setGroupByVariant(current => !current)}>{groupByVariant ? 'do not group': 'group by variant'}</IonButton>
      </>}/>

      <IonContent fullscreen>
        {loading && <IonProgressBar type="indeterminate"/>}

        {groupByVariant && groupedComponents.map(([groupInfo, components]: any) => <ComponentGroup groupDescription={groupInfo} components={components}
          componentName="Aquila" valueColors={aquilaFieldColors}
          factory={(c: any, index: number) => <AquilaItem aquila={c} key={index} showArchives={showArchives}/>}
        />)}

        {!groupByVariant && data && <IonList>
          <IonItemDivider>
            {data?.aquilas?.length} Aquilas
          </IonItemDivider>
          {data.aquilas.map((aquila: any, index: number) => <AquilaItem aquila={aquila} key={index} showArchives={showArchives}/>)}
        </IonList>}
      </IonContent>
    </IonPage>
  );
};

export default Aquilas;