import { IonCardHeader, IonCardTitle, IonCard, IonCardContent, IonItem, IonSegment, IonSegmentButton, IonLabel, IonButton, IonIcon, IonList, IonSpinner, IonCheckbox, IonRange, IonProgressBar, IonRow, IonContent, IonToggle, IonSelect, IonSelectOption } from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {Stage, Layer, Line, Arrow, Text, Image, Circle, Label, Tag, Group, Rect} from 'react-konva';
import { close, eye, key, } from 'ionicons/icons';
import useImage from 'use-image';
import { gql, } from '@apollo/client';
import { LocalizationMap } from './LocalizationMap';
import ParamConfigButton from './ParamConfigButton';
import { StemMap } from './StemMap';
import { SidePanel } from './SidePanel';
import { Stem, StemSliders, useStemConfig } from './Stem';
import { rainbowColor } from '../rainbowColor';
import useDrag from '../hooks/useDrag';
import { AddRuleButton } from './AddRuleButton';
import { BagItemFromId } from './BagItem';
import { useMutationWithElevatedRole } from '../hooks/hasuraHooks';
import { ScoreButton, ScoreVisualization, } from './EvaluationScore';
import Message from '../shared-components/Message';
import { DiagnosticMsgItem } from './StatusList';
import { stems_to_danger_zone } from './lib/planner/accel.js';
import { Spot, SpotSprayConfig, useSpotSpraySim } from './SpotSpraySim';
import { CroplineTimeline } from './CroplineTimeline';
import { TrackFinder } from './TrackFinder';
import { TrackTrendEstimator } from './TrackTrendEstimator';
import { KonvaStage } from '../shared-components/KonvaStage';
import ColorMap from './ColorMap';

import { CameraHeightEstimation } from './CameraHeightEstimation';
import { TalpaDepthGuidance } from './TalpaDepthGuidance';

const evaluateImageData = (info: any) => {
  const rotation = Math.atan2(info.top_right_y - info.top_left_y, info.top_right_x - info.top_left_x) / Math.PI * 180
  const imageHeight = Math.hypot(info.top_left_x - info.bottom_left_x, info.top_left_y - info.bottom_left_y)
  const imageWidth = Math.hypot(info.top_left_x - info.top_right_x, info.top_left_y - info.top_right_y)
  return {rotation, imageHeight, imageWidth, info}
}


export const StitchedImage: React.FC<any> = ({image=undefined, image_url, data, reverseImage=true, ...props}: any) => {
  const info = data.info
  const {rotation, imageHeight, imageWidth} = evaluateImageData(info)
  const [tmp_image] = useImage(image_url)
  if (image === undefined) {
    image = tmp_image
  }

  return <Image image={image} 
    x={info.top_left_x}
    y={info.top_left_y}
    height={imageHeight}
    width={imageWidth}
    rotation={rotation}
    scaleY={reverseImage ? -1 : 1}
    {...props}
  />
}

export const Map: React.FC<any> = ({evaluation, resultData, height, uri, refetch, fuserNetStems}: any) => {
  
  const stitched_image = resultData?.stitched_image
 
  const [stemConfig, setStemConfig, stemFilter] = useStemConfig()

  const [config, setConfig] = useState<any>({
    reverseImage: !evaluation?.bag?.aquila?.camera_is_flipped,
    stemShowFused: true,
    stemShowRaw: false,
    treatmentShow: false,
    lidarShow: false,
    groundTruthShow: false,
    scoreShow: false,
    pathsShow: false,
    sprayerSimShow: false,
    pathsColor: false,
    pathsArea: false,
    lidarOpacity: 0.2,
  })
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  
  const updatePosition = (dx : number, dy : number) => {
    setOffsetX(offsetX - dx);
    setOffsetY(offsetY - dy);
  }

  const [scale, setScale] = useState(1000.0)

  const {onDragStart, onDragMove, onWheel, unsubscribe} = useDrag(evaluation.id, updatePosition, setScale);
  useEffect(() => (unsubscribe as any as void), []);

  const evalCompare = window.location.href.match(/evaluation\/(\d+,)+\d+$/) !== null ? 1.0 : 0.0;

  const [image] = useImage(uri(stitched_image?.file))
  const treatment_map = resultData?.stitched_gridmap?.treatment_map
  const [treatment_map_image] = useImage(treatment_map?.file && uri(treatment_map?.file))
  const lidar_map = resultData?.stitched_lidar_gridmap?.lidar_map
  const [selected_lidar_grid, setSelected_lidar_grid] = useState<any>(null)
  const [info, setInfo] = useState<any>(null)

  const [insertGroundtruth] = useMutationWithElevatedRole(gql`
    mutation InsertGroundtruth($object: ground_truths_insert_input = {}) {
      insert_ground_truths_one(object: $object) {
        id
      }
    }
  `)

  const layerRef = React.useRef<any>();

  // Check if there is a ground truth for this evaluation (must match bag and evaluator_config_id)
  const groundTruth = evaluation.bag.ground_truths.find((gt: any) => gt.evaluator_config_id === evaluation.evaluator_config_id)
  const groundTruthEditor = useGroundTruthEditor(groundTruth)
  const spotSpraySim = useSpotSpraySim()

  // load the planner
  const [accel, setAccel] = useState<{ stems_to_danger_zone : typeof stems_to_danger_zone } | null>(null);
  useEffect(()=>{
      try {
          import('./lib/planner/accel').then(mod=>{setAccel(mod)});
      } catch {
          // wasm not supported, nothing we can do really
          console.error("could not load webassembly module")
      }
  }, []);

  // planner parameters
  const [path_safety_x_before, set_path_safety_x_before] = useState(0.02);
  const [path_safety_x_after, set_path_safety_x_after] = useState(0.02);
  const [path_safety_y, set_path_safety_y] = useState(0.02);
  const [path_y_offset, set_path_y_offset] = useState(0.0);
  const [path_activate_sbm, set_path_activate_sbm] = useState(false);
  const [path_sbm_before, set_path_sbm_before] = useState(0.02);
  const [path_sbm_after, set_path_sbm_after] = useState(0.02);
  const [show_danger_zone, set_show_danger_zone] = useState(false);

  const path_props = [
    {name: "safety (before)", val: path_safety_x_before, set: set_path_safety_x_before, min: 0, max: 0.15, step: 0.01, sbm_only: false},
    {name: "safety (after)", val: path_safety_x_after, set: set_path_safety_x_after, min: 0, max: 0.15, step: 0.01, sbm_only: false},
    {name: "safety (side)", val: path_safety_y, set: set_path_safety_y, min: 0, max: 0.15, step: 0.01, sbm_only: false},
    {name: "overlap", val: path_y_offset, set: set_path_y_offset, min: -0.04, max: 0.1, step: 0.01, sbm_only: false},
    {name: "small beets – treat before", val: path_sbm_before, set: set_path_sbm_before, min: 0, max: 0.15, step: 0.01, sbm_only: true},
    {name: "small beets – treat after", val: path_sbm_after, set: set_path_sbm_after, min: 0, max: 0.15, step: 0.01, sbm_only: true},
  ];

  if (!stitched_image?.info) {return null}
  const stitched_image_info = evaluateImageData(stitched_image.info)

  const width = window.visualViewport?.width  || 0
  height = height || window.visualViewport?.height || 0

  const layerRotation = stitched_image_info.rotation  // Note: set to 0 to keep everything in map frame

  if (image === undefined) {
      return <IonSpinner style={{height: 100, width: "100%", margin: "auto"}}/>
  }

  const link_position = resultData?.link_position
  const link_positions = resultData?.link_positions
  const stems = resultData?.stems;
  const addGroundTruth = (stems: any) => {
    insertGroundtruth({variables: {object: {
      evaluator_config_id: evaluation.evaluator_config_id,
      bag_id: evaluation.bag_id,
      data: {
        fused_stems: stems
      }
    }}})
  }

  let danger_zone = null;
  if (show_danger_zone && accel && stems) {
    const stems_x : number[] = stems.fused_stems.map((s : number[])=>s[0]);
    const stems_y : number[] = stems.fused_stems.map((s : number[])=>s[1]);
    
    const side = "left";

    danger_zone = JSON.parse(accel.stems_to_danger_zone(new Float32Array(stems_x), new Float32Array(stems_y), path_safety_x_before, path_safety_x_after, path_safety_y,path_y_offset,side,32,path_activate_sbm,path_sbm_before,path_sbm_after));
    danger_zone.left_central_line = danger_zone.central_line.slice(2,-2);
    let left_path_points : number[] = [];
    let right_path_points : number[] = [];
    for (let [x, y] of danger_zone.central_line) {
      if (x < stems.fused_stems[0][0]) {
        continue;
      }
      if (x > stems.fused_stems.slice(-1)[0][0]) {
        continue;
      }
      left_path_points.push(x);
      left_path_points.push(y);
      right_path_points.push(x);
      right_path_points.push(y - 2*path_y_offset);
    }
    danger_zone.left_path_points = left_path_points;
    danger_zone.right_path_points = right_path_points;
  }
  // Find mean position of stems in y to simulate the robot position for spot spraying
  const meanStemY = !stems?.fused_stems ? 0 : (stems.fused_stems.map((s: any) => s[1]).reduce((a: number, b: number) => a + b, 0) / stems.fused_stems.length)

  const listItems = lidar_map !== undefined ? lidar_map.map((lidar_map : any) => {
    if (lidar_map?.file.includes(".png")) {
      return <IonSelectOption key={lidar_map?.file} value={lidar_map}>{lidar_map?.file.replace("lidar_map_", "").replace(".png", "")}</IonSelectOption>
    }
  }) : <IonSelectOption value={""}>No lidar maps available</IonSelectOption>

  return <>
    <SidePanel info={info}>
      <div style={{display: "block", overflowY: "scroll"}}>
      {/*<IonItem>
        <IonLabel>Flip y axis</IonLabel>
        <IonCheckbox checked={config.reverseImage} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, reverseImage: e.detail.checked}))}/>
      </IonItem>*/}
      <IonItem>
        <IonLabel>Show fused stems</IonLabel>
        <IonCheckbox checked={config.stemShowFused} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, stemShowFused: e.detail.checked}))}/>
      </IonItem>
      <IonItem>
        <IonLabel>Show raw stems</IonLabel>
        <IonCheckbox checked={config.stemShowRaw} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, stemShowRaw: e.detail.checked}))}/>
      </IonItem>
      <IonItem>
        <IonLabel>Show ground truth</IonLabel>
        <IonCheckbox checked={config.groundTruthShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, groundTruthShow: e.detail.checked}))}/>
      </IonItem>
      {evaluation.score_details && <IonItem>
        <IonLabel>Show score</IonLabel>
        <IonCheckbox checked={config.scoreShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, scoreShow: e.detail.checked}))}/>
      </IonItem>}
      <IonItem>
        <IonLabel>Show treatment map</IonLabel>
        <IonCheckbox checked={config.treatmentShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, treatmentShow: e.detail.checked}))}/>
      </IonItem>
      <IonItem>
        <IonSelect aria-label="Lidar" interface="popover" value={selected_lidar_grid} placeholder="select layer"
           onIonChange={(e: any) => setSelected_lidar_grid(e.detail.value)}>
          {listItems}
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonLabel>Show lidar map</IonLabel>
        <IonCheckbox checked={config.lidarShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, lidarShow: e.detail.checked}))}/>
      </IonItem>
      <IonItem>
        <IonLabel style={{ overflow: 'visible'}} position='stacked'>Lidar Map Opacity:  {config.lidarOpacity}</IonLabel>
        <IonRange disabled={!(config.lidarShow && selected_lidar_grid)} style={{ padding: "5px 0px" }} pin={true} value={config.lidarOpacity * 100} pinFormatter={(value: number) => `${value}%`} 
          onIonChange={(e: any) => setConfig((prev: any) => ({...prev, lidarOpacity: (e.detail.value / 100)}))} />
      </IonItem>
      <IonItem>
        <IonLabel>Show sprayer sim</IonLabel>
        <IonCheckbox checked={config.sprayerSimShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, sprayerSimShow: e.detail.checked}))}/>
      </IonItem>
      <IonItem>
        <IonLabel>Show applicator paths</IonLabel>
        <IonCheckbox checked={config.pathsShow} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, pathsShow: e.detail.checked}))}/>
      </IonItem>
      {config.pathsShow && <IonItem>
        <IonLabel>Alternate path colors</IonLabel>
        <IonCheckbox checked={config.pathsColor} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, pathsColor: e.detail.checked}))}/>
      </IonItem>}
      {config.pathsShow && <IonItem>
        <IonLabel>Show treated area</IonLabel>
        <IonCheckbox checked={config.pathsArea} onIonChange={(e: any) => setConfig((prev: any) => ({...prev, pathsArea: e.detail.checked}))}/>
      </IonItem>}
      <StemSliders config={stemConfig} setConfig={setStemConfig} />
      <table style={{width: "100%", lineHeight: 2}}>
        <tr>
          <td style={{padding: "0 1em"}}><label>show danger zone</label></td>
          <td style={{padding: "0 1em"}}><IonToggle checked={show_danger_zone} onIonChange={evt=>set_show_danger_zone(evt.detail.checked)} /></td>
        </tr>
        {show_danger_zone && <>
          <tr>
            <td style={{padding: "0 1em"}}><label>small beets mode</label></td>
            <td style={{padding: "0 1em"}}><IonToggle checked={path_activate_sbm} onIonChange={evt=>set_path_activate_sbm(evt.detail.checked)} /></td>
          </tr>
          {path_props.map((prop, idx)=>{
            if (prop.sbm_only && !path_activate_sbm) return;
            return <tr key={idx}>
              <td style={{padding: "0 1em"}}><label>{prop.name}: </label></td>
              <td style={{textAlign: "right", maxWidth: "3em"}}><pre style={{display:"inline"}}>{Math.round(prop.val*100)}</pre>&nbsp;cm</td>
              <td style={{padding: "0 1em"}}>
                <input
                  type="range"
                  min={prop.min}
                  max={prop.max}
                  step={prop.step}
                  value={prop.val}
                  onChange={evt=>prop.set(parseFloat(evt.target.value))}
                  style={{width: "100%"}}
                />
              </td>
            </tr>
          })
        }</>}
      </table>
    </div>
    </SidePanel>

    <div style={{position:"absolute", zIndex: 1000, display: "flex"}}>
      {config.sprayerSimShow && <SpotSprayConfig {...spotSpraySim}/>}
      {config.groundTruthShow && <>
        {/* Groundtruth related stuff */}
        {resultData?.stems?.fused_stems && <IonButton onClick={() => groundTruthEditor.setStems([...resultData.stems.fused_stems])}>Set stems</IonButton>}
        {groundTruthEditor.changed && <>
          <IonButton onClick={() => groundTruthEditor.reset()}>Reset</IonButton>
          <IonButton onClick={() => {addGroundTruth(groundTruthEditor.stems); refetch()}}>Save</IonButton>
        </>}
      </>}
      <ScoreButton evaluations={[evaluation]}/>
    </div>
    {selected_lidar_grid?.min_value && selected_lidar_grid?.max_value && config.lidarShow &&  <div style={{ color:'black', display: 'flex', justifyContent:'flex-end', margin: '0 50px 0 0'}}>
      <ColorMap minValue={selected_lidar_grid.min_value} maxValue={selected_lidar_grid.max_value} />
    </div>}
    <Stage
      draggable
      onDragStart={onDragStart}
      onDragMove={onDragMove}
      onDragEnd={onDragMove}
      onDblClick={(e: any) => {
        // From https://stackoverflow.com/a/56870752
        var transform = layerRef.current.getAbsoluteTransform().copy();
        transform.invert();
        const pos = e.target.getStage().getPointerPosition();
        const newStemPos = transform.point(pos);
        // Add stem at pointer position
        groundTruthEditor.setStems([...groundTruthEditor.stems, [newStemPos.x, newStemPos.y, 0, 0, 0]])
      }}
      onWheel={onWheel}
      width={width}
      height={height}
      style={{width: width+"px", height: height+"px"}}
      rotation={layerRotation}
      offsetX={evalCompare*offsetX + (stitched_image_info.info.bottom_right_x + stitched_image_info.info.top_left_x) / 2. * scale}
      offsetY={evalCompare*offsetY + (stitched_image_info.info.bottom_right_y + stitched_image_info.info.top_left_y) / 2. * scale * -1}
      x={width / 2} y={height / 2}
    >
      <Layer scaleX={scale} scaleY={-scale} ref={layerRef}>
        <StitchedImage data={stitched_image} reverseImage={config?.reverseImage} image={image} />
        {treatment_map && treatment_map_image && config.treatmentShow && <StitchedImage data={treatment_map} reverseImage={true} image={treatment_map_image} opacity={0.2}/>}
        {selected_lidar_grid && config.lidarShow && <StitchedImage data={selected_lidar_grid} reverseImage={true} image_url={uri(selected_lidar_grid.file)} opacity={config.lidarOpacity}/>}
        
        {/* Origin frame */}
        <Arrow points={[0, 0, 0, 0.5]} stroke="black" strokeWidth={0.01} pointerLength={0.06} pointerWidth={0.05} />
        <Text x={0} y={0.5} text={"y"} scaleY={-1} fontSize={0.05} rotation={layerRotation} />
        <Arrow points={[0, 0, 0.5, 0.]} stroke="black" strokeWidth={0.01} pointerLength={0.06} pointerWidth={0.05} />
        <Text x={0.5} y={0} scaleY={-1} text={"x"} fontSize={0.05} rotation={layerRotation} />

        {link_position && <>
            {link_position?.points?.map((p: number[]) => <Circle x={p[0]} y={p[1]} radius={0.01} fill="white" stroke={"black"} strokeWidth={0.001}/>)}
            <Arrow points={[
                link_position?.points[0][0], link_position?.points[0][1],
                link_position?.points[Math.min(link_position?.points.length -1, 30)][0], link_position?.points[Math.min(link_position?.points.length - 1, 30)][1]
            ]}
                stroke="black" strokeWidth={0.01} fill="white" pointerLength={0.06} pointerWidth={0.05} />
            <Text x={link_position?.points[0][0]} y={link_position?.points[0][1]} text={`Start of ${link_position?.link}`} scaleY={-1} fontSize={0.1} rotation={layerRotation}/>
        </>}

        {link_positions && link_positions.map((l: any) => {
          const pointForArrowDirection = l?.points[Math.min(l?.points.length -1, 30)]
          return <>
            {l?.points?.map((p: number[]) => <Circle x={p[0]} y={p[1]} radius={0.01} fill="white" stroke={"black"} strokeWidth={0.001}/>)}
            <Arrow points={[
                l?.points[0][0], l?.points[0][1],
                pointForArrowDirection[0], pointForArrowDirection[1]
            ]}
                stroke="black" strokeWidth={0.01} fill="white" pointerLength={0.06} pointerWidth={0.05} />
            <Text x={l?.points[0][0]} y={l?.points[0][1]} text={`Start of ${l?.frame_id}`} scaleY={-1} fontSize={0.1} rotation={layerRotation}/>
        </>})}

        {stems && <>
            {config?.stemShowRaw && stems?.new_stems?.filter(stemFilter)?.map(
              (s: number[], i: number) => <Stem scale={scale} stem={s} confidence={s[2]} area={s[3]} nObservations={s[4]} 
                layerRotation={layerRotation} radius={0.007} stroke={"lightgray"} setInfo={setInfo}  key={`raw_${i}`} />)}

            {config?.stemShowFused && stems?.fused_stems?.filter(stemFilter)?.map(
              (s: number[], i: number) => <Stem scale={scale} stem={s} confidence={s[2]} area={s[3]} nObservations={s[4]} id={s[5]}
                layerRotation={layerRotation} radius={0.02} stroke={"#3dc2ff"} setInfo={setInfo} key={`fused_${i}`} />)}

            {config?.sprayerSimShow && stems?.fused_stems?.filter(stemFilter)?.map(
              (s: number[], i: number) => <Spot stem={s} {...spotSpraySim} scale={scale} yOffset={meanStemY}/>)}
        </>}

        {fuserNetStems?.map(
          (s: number[], i: number) => <Stem scale={scale} stem={s} confidence={s[2]} id={s[5]}
            layerRotation={layerRotation} radius={0.02} stroke={"red"} setInfo={setInfo} key={`fusernet_stem_${i}`} />)}

        {config?.scoreShow && evaluation?.score_details && <ScoreVisualization evaluation={evaluation} scale={scale} layerRotation={layerRotation} setInfo={setInfo} />}

        {config?.pathsShow && resultData?.paths?.map((path: any, i: number) => 
          <TreatmentPath path={path} color={config.pathsColor ? rainbowColor(i / 2, 10, 1.): "crimson"}/>)}
        {config?.pathsShow && resultData?.paths_left?.map((path: any, i: number) => 
          <TreatmentPath path={path} color={config.pathsColor ? rainbowColor(i / 2, 10, 1.): "crimson"} side="left" showArea={config.pathsArea}/>)}
        {config?.pathsShow && resultData?.paths_right?.map((path: any, i: number) => 
          <TreatmentPath path={path} color={config.pathsColor ? rainbowColor(i / 2, 10, 1.): "crimson"} side="right" showArea={config.pathsArea}/>)}

        {danger_zone && <>
          <Line
            points={danger_zone.left_path_points}
            stroke="white"
            strokeWidth={0.005}
            lineJoin="miter"
            opacity={0.8}
          />
          <Line
            points={danger_zone.right_path_points}
            stroke="white"
            strokeWidth={0.005}
            lineJoin="miter"
            opacity={0.8}
          />
          {danger_zone.polygons.map((coords : number[][], idx : number)=>{
            let points = [];
            for (let coord of coords) {
              if (coord[0] < stems.fused_stems[0][0]) {
                return;
              }
              if (coord[0] > stems.fused_stems.slice(-1)[0][0]) {
                return;
              }
              points.push(coord[0]);
              points.push(coord[1]);
            }
            return <Line
              points={points}
              stroke="white"
              opacity={0.8}
              strokeWidth={0.005}
              key={idx}
              closed />
          })}
        </>}

        {config?.groundTruthShow && <GroundTruth groundTruthEditor={groundTruthEditor} layerRotation={layerRotation} setInfo={setInfo}/>}
      </Layer>
    </Stage>
  </>
}


export const useGroundTruthEditor = (groundTruth: any) => {
  const initialStems = groundTruth?.data?.fused_stems || []
  const [stems, setStems] = useState<any>([...initialStems])
  const reset = () => setStems([...initialStems])
  const changed = JSON.stringify(stems) !== JSON.stringify(initialStems)
  return {
    stems, setStems, changed, reset
  }
}


export const GroundTruth: React.FC<any> = ({groundTruthEditor, setInfo, ...props}: any) => {
  const radius = 0.04  // Distance used for comparison by default
  return <>
    {
      groundTruthEditor.stems.map(
        (s: any[], i: number) => <Stem
          draggable stem={s} ignoreGroundTruth={s[2] === true}
          radius={radius} opacity={0.5} strokeWidth={radius} stroke={s[2] === true ? "white" : "#058b8c"} key={`gt_${s[0]}${s[1]}${s[2]}{i}`}
          {...props}  _useStrictMode setInfo={setInfo}
          onDblClick={(e: any) => {
            e.evt.stopPropagation()
            if (s[2] === true) {
              // Remove stem if already ignored
              groundTruthEditor.setStems(groundTruthEditor.stems.filter((_: any, ix: number) => ix !== i))
              setInfo(`Removed stem`)
            }
            else {
              // Change current stem to ignore (3rd element in stem array)
              groundTruthEditor.setStems([...groundTruthEditor.stems.map((sx: any, ix: number) => i !== ix ? sx: [s[0], s[1], !(s[2] === true)])])
              setInfo(`Ignored ground truth stem (this position won't be used in scoring)`)
            }
          }}
          onDragEnd={(e: any) => {
            const distance = Math.hypot(s[0] - e.target.attrs.x, s[1] - e.target.attrs.y)
            if (distance > 0.4) {
              // Remove stem if distance moved is too large
              groundTruthEditor.setStems(groundTruthEditor.stems.filter((_: any, ix: number) => ix !== i))
            }
            else {
              // Change stem position
              const newStem = JSON.parse(JSON.stringify(s))  // Deep copy so we don't edit the original stem
              newStem[0] = e.target.attrs.x
              newStem[1] = e.target.attrs.y
              groundTruthEditor.stems[i] = newStem
              groundTruthEditor.setStems([...groundTruthEditor.stems])
            }
          }}
        />)
    }
  </>
}

export const TreatmentPath: React.FC<any> = ({path, color, side, showArea}: any) => {
  const [mouseOver, setMouseover] = useState(false)
  
  // Get x positions of start and end to extrapolate for polygon
  const start = path[0][0]
  const end = path[path.length - 1][0]

  const slicedPoints = path.slice(2, path.length - 2)  // Remove points at edges (path doesn't give good end points)
  // In some cases: add points going up to form an area polygon
  const points = (!side || !showArea) ? slicedPoints.flat() : ([start, side === "left" ? 1 : -1].concat(slicedPoints.flat()).concat([end, side === "left" ? 1: -1]))
  return <Line closed={showArea} fill={showArea ? "crimson" : ""} points={points} strokeWidth={mouseOver ? 0.01 : 0.002} stroke={showArea ? "" : color}
    opacity={showArea ? 1. : (mouseOver ? 0.5 : 0.3)} onMouseOver={() => setMouseover(true)} onMouseOut={() => setMouseover(false)} />
}


export const useFetchJson = (path: string) => {
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  useEffect(() => {
    if (path) {
      // Fetch json file and store result as either data or error
      fetch(path, {method: "GET", headers: {"content-type": "application/json;charset=UTF-8"}})
        .then((response) => {if (!response.ok) {setError(response.statusText)} return response.json()})
        .then(setData);
    }
    else {
      setError("No path providede")
    }
  }, [path])   // Fetch again if path changes

  return {
    data,
    error,
    loading: data === null && error === null
  }
}

export const TextFromSrc: React.FC<any> = ({path}) => {
  const {data, error, loading} = useFetchJson(path)
  if (loading) {
    return <IonProgressBar type="indeterminate"/>
  }
  if (error !== null) {
    return <Message error={true}>
      {JSON.stringify(error)}
    </Message>
  }
  if (data !== null) {
    return <code>
      <pre>
        {JSON.stringify(data, null, 2)}
      </pre>
    </code>
  }
  return null
}


export const File: React.FC<any> = ({filename, uri}) => {
  const isImage = filename.includes(".png") || filename.includes(".jpg")
  const isJson = filename.includes(".json")
  const path = uri(filename);
  const [showImage, setShowImage] = useState(false)
  return <>
    <IonItem>
      <a href={path}>{filename}</a>
      {(isImage || isJson) && <IonButton slot="end" onClick={() => setShowImage(!showImage)}><IonIcon icon={showImage ? close : eye}/></IonButton>}
    </IonItem>
    {showImage && isJson && <TextFromSrc path={path}/>}
    {showImage && isImage && <img src={path}/>}
  </>
}


export const useExtendedResultData = (evaluation: any) => {
  const [resultData, setResultData] = useState<any>(null)
  useEffect(() => {
    if (!evaluation?.result_data) { return }

    // Set the basic result data
    setResultData({...evaluation?.result_data})

    if(evaluation?.result_data?.files.includes("result.json"))
    {
      // Get json to "extend" result data
      const json_uri = `/evaluation-files/ev${evaluation["id"]}.result.json`
      fetch(json_uri, {method: "GET", headers: {"content-type": "application/json;charset=UTF-8"}})
        .then((response) => {if (!response.ok) {throw Error(response.statusText);} return response.json()})
        .then((data) => setResultData({...evaluation.result_data, ...data }))
    }
  }, [evaluation])
  return resultData
}


export const Overview: React.FC<any> = ({evaluation, AddEvaluation, EvaluationItemFromId, height, uri}: any) => {
  return <IonList style={{overflow: "scroll", maxHeight: height}}>
    {evaluation?.execution_computer === null && <IonItem>Waiting for machine to pick evaluation</IonItem>}
    <BagItemFromId bag_id={evaluation.bag.id} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId} />
    {evaluation?.execution_computer !== null && <IonItem>
      <IonLabel>Execution machine</IonLabel>
      {evaluation.execution_computer.hostname}
    </IonItem>}
    {evaluation?.param_config && <IonItem>
      <ParamConfigButton param_config={evaluation.param_config}/>
    </IonItem>}
    {evaluation?.result_error && <IonCard>
      <IonCardHeader><IonCardTitle>Error</IonCardTitle></IonCardHeader>
      <IonCardContent>
        <p>{evaluation?.result_error}</p>
      </IonCardContent>
    </IonCard>}
    {evaluation?.result_data?.files?.length > 0 && <IonCard>
      <IonCardHeader><IonCardTitle>Files</IonCardTitle></IonCardHeader>
      {evaluation?.result_data?.files.map((filename: string) => <File uri={uri} filename={filename} key={filename}/>)}
    </IonCard>}
    {evaluation?.result_data === null && evaluation?.result_error === null && <IonItem>
      No results yet
    </IonItem>}
  </IonList>
}

function TalpaHeights({evaluation, resultData}: any): JSX.Element {
  const [heightData, setHeightData ] = useState<any>(null)
  useEffect(() => {

  if(resultData?.files.includes("heights.json"))
  {
    // Get json to "extend" result data
    const json_uri = `/evaluation-files/ev${evaluation["id"]}.heights.json`
    fetch(json_uri, {method: "GET", headers: {"content-type": "application/json;charset=UTF-8"}})
      .then((response) => {if (!response.ok) {throw Error(response.statusText);} return response.json()})
      .then((data) => setHeightData(data))
  }
  }, [evaluation, resultData])

  const [keys, setKeys] = useState<any>(null)
  useEffect(() => {
    if (heightData) {
      setKeys(Object.keys(heightData?.fused_stems))
    }
  }, [heightData])


  const width = window.visualViewport?.width  || 0
  const height = window.visualViewport?.height || 0

  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  
  const updatePosition = (dx : number, dy : number) => {
    setOffsetX(offsetX - dx);
    setOffsetY(offsetY - dy);
  }

  const [scale, setScale] = useState(1000.0)
  const {onDragStart, onDragMove, onWheel, unsubscribe} = useDrag(evaluation.id, updatePosition, setScale);
  return heightData && keys && <Stage
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragMove}
        onWheel={onWheel}
        draggable
        width={width}
        height={height}
        style={{width: width+"px", height: height+"px"}}
        offsetX={heightData.fused_stems[keys[Math.round(keys.length/2)]].x * scale}
        offsetY={heightData.fused_stems[keys[Math.round(keys.length/2)]].z * 10 * -scale}>
        {/* x={width / 2} y={height / 2} */}
        <Layer scaleX={scale} scaleY={-scale}>
        {heightData?.talpa_heights && Object.keys(heightData?.talpa_heights).map((h: any, index) => {
          let pointsXY = [];
          for (let i = 0; i < heightData?.talpa_heights[h].length; i++) {
            pointsXY.push(heightData?.talpa_heights[h][i].x);
            pointsXY.push(heightData?.talpa_heights[h][i].z * 10);
          }
          return <Line x={0} y={0} stroke= {index == 1 ? "black" : "yellow"} points={pointsXY} strokeWidth={0.01} />})}
        {heightData?.fused_stems && Object.keys(heightData?.fused_stems).map((keyName, h: any) => {
          return <Group>
                  <Circle x={heightData?.fused_stems[keyName].x} y={heightData?.fused_stems[keyName].z * 10} radius={0.02} fill="green" />
                  <Text scaleY={-1} x={heightData?.fused_stems[keyName].x} y={heightData?.fused_stems[keyName].z * 10} text={keyName} fontSize={0.1} />
                  <Text scaleY={-1} x={heightData?.fused_stems[keyName].x} y={heightData?.fused_stems[keyName].z * 10 - 0.1} text={heightData?.fused_stems[keyName].z} fontSize={0.02} />
                </Group>
        })}
        

        <Arrow points={[0, 0, 0, 5]} stroke="black" strokeWidth={0.01} pointerLength={0.06} pointerWidth={0.05} />
        <Text x={0} y={0.5} text={"z"} scaleY={-1} fontSize={0.5}  />
        <Arrow points={[0, 0, 5, 0.]} stroke="black" strokeWidth={0.01} pointerLength={0.06} pointerWidth={0.05} />
        <Text x={0.5} y={0} scaleY={-1} text={"x"} fontSize={0.5} />

        </Layer>

    </Stage>

}


export const ShowEvaluation: React.FC<any> = ({evaluation, height, AddEvaluation, EvaluationItemFromId, refetch = () => {}}: any) => {
  const [mode, setMode] = useState("overview")
  useEffect(() => {
    // pull the result data from the json file if it exists

    if (evaluation?.result_data?.files?.includes("stitched_image.jpg")) {
      setMode("stitched_image")
    }
    if (evaluation?.result_data?.files?.includes("diagnostics.json")) {
      setMode("diagnostics")
    }
    if (evaluation?.result_data?.files?.includes("cropline_bases_link.json")) {
      setMode("cropline_timeline")
    }
    if (evaluation?.result_data?.files?.includes("track_finder.json")) {
      setMode("track_finder")
    }
    if (evaluation?.evaluator_config?.evaluator_name === "track_trend_estimator") {
      setMode("estimated_track_trend")
    }
    if (evaluation?.evaluator_config?.evaluator_name === "fusernet") {
      setMode("fusernet")
    }
    if (evaluation?.evaluator_config?.evaluator_name === "camera_height_estimation") {
      setMode("camera_height_estimation")
    }
    if (evaluation?.evaluator_config?.evaluator_name === "depth_guidance") {
      setMode("depth_guidance")
    }
  }, [evaluation])

  const resultData = useExtendedResultData(evaluation)  // Uses result.json
  const baseResultData = useExtendedResultData(evaluation?.base_evaluation)
  const uri = (filename : string) => {
    return `/evaluation-files/ev${evaluation.id}.${filename}`;
  }
  const baseEvalUri = (filename : string) => {
    if (!evaluation.base_evaluation) {return ""}
    return `/evaluation-files/ev${evaluation.base_evaluation.id}.${filename}`;
  }

  return <>
    <IonRow style={{display: 'flex', flexDirection: 'row'}}>
    <span style={{display: 'flex', alignItems: 'center', fontSize: '0.6rem', paddingLeft: '0.6rem'}}>
      <ParamConfigButton param_config={evaluation?.param_config}/>
      <AddRuleButton
        param_config_id={evaluation?.param_config?.id}
        tag_ids={evaluation?.bag?.bag_tags?.map(({tag}: any) => tag.id)}
        plant_variety_id={evaluation?.bag?.plant_variety?.id}
      />
    </span>
    <IonSegment onIonChange={(e: any) => setMode(e.detail.value)} value={mode} style={{width: 'unset', flexGrow: 1}}>
      <IonSegmentButton value="overview">
        <IonLabel>Overview</IonLabel>
      </IonSegmentButton>
      {resultData?.files?.includes("stitched_image.jpg") && <IonSegmentButton value="stitched_image">
        <IonLabel>Stitched Image</IonLabel>
      </IonSegmentButton>}
      {resultData?.files?.includes("heights.json") && <IonSegmentButton value="talpa_heights">
        <IonLabel>talpe heights</IonLabel>
      </IonSegmentButton>}
      {resultData?.files?.includes("diagnostics.json") && <IonSegmentButton value="diagnostics">
        <IonLabel>Diagnostics</IonLabel>
      </IonSegmentButton>}
      {resultData?.files?.includes("track_finder.json") && <IonSegmentButton value="track_finder">
        <IonLabel>Track finder</IonLabel>
      </IonSegmentButton>}
      {resultData?.files?.includes("croplines_base_link.json") && <IonSegmentButton value="cropline_timeline">
        <IonLabel>Cropline Timeline</IonLabel>
      </IonSegmentButton>}
      {resultData?.localization && <IonSegmentButton value="localization">
        <IonLabel>Localization</IonLabel>
      </IonSegmentButton>}
      {resultData?.all_stems && <IonSegmentButton value="all_stems">
        <IonLabel>Stems</IonLabel>
      </IonSegmentButton>}
      {resultData?.fuser_net_stems && <IonSegmentButton value="fusernet">
        <IonLabel>Fuser net</IonLabel>
      </IonSegmentButton>}
      {evaluation?.evaluator_config?.evaluator_name === "track_trend_estimator" && <IonSegmentButton value="estimated_track_trend">
        <IonLabel>Estimated Track Trends</IonLabel>
      </IonSegmentButton>}
      {evaluation?.evaluator_config?.evaluator_name === "depth_guidance" && <IonSegmentButton value="depth_guidance">
        <IonLabel>Depth Guidance</IonLabel>
    </IonSegmentButton>}
    </IonSegment>
    </IonRow>
  
    {mode === "overview" && <>
      <Overview evaluation={evaluation} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId} height={height} uri={uri}/>
      {evaluation.base_evaluation && <IonCard style={{padding: 10, height: "fit-content"}}>
        <h3>Base evaluation: <a href={`evaluation/${evaluation.base_evaluation.id}`}>{evaluation.base_evaluation.id}</a></h3>
        <Overview evaluation={evaluation} AddEvaluation={AddEvaluation} EvaluationItemFromId={EvaluationItemFromId} height={height} uri={uri}/>
      </IonCard>}
    </>}
    {mode === "stitched_image" && <Map evaluation={evaluation} height={height} uri={uri} refetch={refetch} resultData={resultData} />}
    {mode === "talpa_heights" && <TalpaHeights evaluation={evaluation} resultData={resultData} />}
    {mode === "fusernet" && <FuserNet evaluation={evaluation} height={height} uri={uri} refetch={refetch} resultData={resultData} baseEvalUri={baseEvalUri} baseResultData={baseResultData} />}
    {mode === "localization" && <LocalizationMap evaluation={evaluation} resultData={resultData} />}
    {mode === "all_stems" && <StemMap evaluation={evaluation} resultData={resultData} />}
    {mode === "track_finder" && <TrackFinder evaluation={evaluation}/>}
    {mode === "diagnostics" && <Diagnostics evaluation={evaluation}/>}
    {mode === "cropline_timeline" && <CroplineTimeline evaluation={evaluation}/>}
    {mode === "estimated_track_trend" && <TrackTrendEstimator evaluation={evaluation} resultData={resultData}/>}
    {mode === "camera_height_estimation" && <CameraHeightEstimation evaluation={evaluation} resultData={resultData}/>}
    {mode === "depth_guidance" && <TalpaDepthGuidance evaluation={evaluation} resultData={resultData}/>}
  </>
}


export const FuserNet: React.FC<any> = ({evaluation, resultData, height, baseResultData, baseEvalUri, refetch}: any) => {
  return <Map evaluation={evaluation.base_evaluation} height={height} uri={baseEvalUri} refetch={refetch} resultData={baseResultData} fuserNetStems={resultData?.fuser_net_stems}/>
}


export const Diagnostics: React.FC<any> = ({evaluation}: any) => {
  const {data, loading} = useFetchJson(`/evaluation-files/ev${evaluation["id"]}.diagnostics.json`)
  return <IonContent>
    {loading && <IonProgressBar type="indeterminate"/>}
    {data?.map((msg: any) => <DiagnosticMsgItem msg={msg}/>)}
  </IonContent>
}
