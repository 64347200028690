import {
  IonButton,
  IonItem,
  IonLabel,
  IonChip,
  IonAccordion,
  IonToggle,
  IonCol,
  IonGrid,
  IonRow,
  IonAccordionGroup,
  IonSelect,
  IonSelectOption,
  IonInput,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import history  from "../history";
import useWindowDimensions from "../useWindowDimensions";
import {
  useMutationWithElevatedRole,
} from "../hooks/hasuraHooks";
import "./AquilaItem.css";


// Keys to be ignored for Aquila form
const ignoreKeys = ["computer", "aquila_configs", "calibrations"]


export const aquilaFieldColors = {
  has_wide_view: "tertiary",
  has_fullres: "tertiary",
  jai2: "success",
  jai1: "danger",
  has_lidar: "tertiary",
  camera_is_flipped: "danger",
  central_trigger: "success",
  "2021_distribox": "danger",
  "2023_distribox": "success",
  "2021_taurus": "danger",
  "2023_taurus": "success",
}


export const AquilaItem: React.FC<any> = ({ aquila, showArchives }: any) => {
  const [updateAquila] = useMutationWithElevatedRole(
    gql`
      mutation UpdateAquila($id: Int!, $_set: aquilas_set_input = {}) {
        update_aquilas_by_pk(pk_columns: { id: $id }, _set: $_set) {
          id
        }
      }
    `,
    {}
  );

  const [tmpAquila, setTmpAquila] = useState({...aquila})

  const changedFields = Object.keys(tmpAquila).filter((k: string) => !ignoreKeys.includes(k) && tmpAquila[k] !== aquila[k])

  const reset = () => {
    setTmpAquila({...aquila})
  };
  useEffect(reset, [aquila])

  const { isLargeScreen } = useWindowDimensions();
  const taurus_id =
    aquila?.aquila_configs &&
    aquila?.aquila_configs[0]?.robot_config?.taurus_configs[0]?.taurus?.id;
  const onClick = (e: any, robotConfigId: number) => {
    e.stopPropagation();
    history.push(`/robot/${robotConfigId}`);
  };
  const robot_config_id = aquila?.aquila_configs[0]?.robot_config?.id;
  const save = () => {
    if (changedFields.length < 1) return
    const _set = Object.fromEntries(changedFields.map((k: string) => ([k, tmpAquila[k]])))
    console.log(`Save ${JSON.stringify(_set)} for AQL${aquila.id}`)
    updateAquila({
      variables: {
        id: aquila.id,
        _set: _set
      },
    });
  };

  const fieldSetter = (fieldName: string) => {
    return ({detail: {value, checked}}: any) => setTmpAquila((prev: any) => ({...prev, [fieldName]: checked !== undefined ? checked : value}))
  }

  const hasChanged = (fieldName: string) => changedFields.includes(fieldName)

  const props = (fieldName: string) => ({
    onIonChange: fieldSetter(fieldName),
    style: {fontWeight: hasChanged(fieldName) ? "bolder" : undefined},  // Change weight if it has changed
  })

  return (
    <>
      <IonAccordionGroup
        expand="inset"
        style={{ display: aquila?.obsolete && !showArchives ? "none" : "" }}
      >
        <IonAccordion value={aquila?.id}>
          <IonItem slot="header" color="light">
            <IonLabel
              style={{ textDecoration: aquila?.obsolete ? "line-through" : "" }}
            >
              AQL{aquila?.id}
            </IonLabel>
            {isLargeScreen && aquila?.description && <IonLabel>

              <i style={{ margin: "0 15px" }}>{aquila?.description}</i>
            </IonLabel>}
            {robot_config_id && (
              <>
                {aquila?.aquila_configs[0]?.standalone && (
                  <IonChip
                    onClick={(e: any) =>
                      onClick(e, aquila?.aquila_configs[0]?.robot_config?.id)
                    }
                    outline
                  >
                    Standalone
                  </IonChip>
                )}
                {taurus_id && (
                  <IonChip
                    onClick={(e: any) =>
                      onClick(e, aquila?.aquila_configs[0]?.robot_config?.id)
                    }
                    outline
                    color="secondary"
                  >
                    GT{taurus_id}
                  </IonChip>
                )}
              </>
            )}
            {aquila?.has_lidar && (
              <IonChip outline color={aquilaFieldColors["has_lidar"]}>
                lidar
              </IonChip>
            )}
            {aquila?.has_wide_view && (
              <IonChip color={aquilaFieldColors["has_wide_view"]} outline>
                wide-view
              </IonChip>
            )}
            {aquila?.has_fullres && (
              <IonChip color={aquilaFieldColors["has_fullres"]} outline>
                fullres
              </IonChip>
            )}
            {aquila?.camera_is_flipped && (
              <IonChip color={aquilaFieldColors["camera_is_flipped"]} outline>
                camera_is_flipped
              </IonChip>
            )}
            {aquila?.camera_type && (
              <IonChip color={aquilaFieldColors[(aquila.camera_type as "jai1"|"jai2")]} outline>
                {aquila?.camera_type}
              </IonChip>
            )}
          </IonItem>

          <IonGrid slot="content" style={{bottomBorder: "groove"}}>
            
            <IonItem>
              {changedFields.length > 0 && <>
                <IonButton onClick={save} slot="start">
                  Save
                </IonButton>
                <IonButton fill="outline" color="danger" onClick={reset} slot="start">
                  Reset
                </IonButton>
              </>}
              <IonChip slot="end"><IonLabel>{aquila.updated_at} (last update)</IonLabel></IonChip>
            </IonItem>

            <IonRow>
              <IonCol>
                Housing
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.housing} {...props("housing")}>
                  <IonSelectOption value="2021">Housing 2021</IonSelectOption>
                  <IonSelectOption value="2023">Housing 2023 (with sunshield)</IonSelectOption>
                  <IonSelectOption value="2024">Housing 2024</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Mount
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.mount} {...props("mount")}>
                  <IonSelectOption value="2021">2021 (for low Schmotzer beam)</IonSelectOption>
                  <IonSelectOption value="2023_lowered">2023_lowered (for high Schmotzer beam to keep 50 cm camera height)</IonSelectOption>
                  <IonSelectOption value="2023">2023 (for low Schmotzer beam)</IonSelectOption>
                  <IonSelectOption value="2024">2024 (for high positioned Aquila)</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Connector plug
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.connector_plug} {...props("connector_plug")}>
                  {["2021_distribox", "2023_distribox", "2024"].map((v) => <IonSelectOption value={v} key={v}>{v}</IonSelectOption>)}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Electronics
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.electronics_version} {...props("electronics_version")}>
                  {["2022", "2023", "2024"].map((v) => <IonSelectOption value={v} key={v}>{v}</IonSelectOption>)}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Talpa CAN buses
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.talpa_can_buses} {...props("talpa_can_buses")}>
                  <IonSelectOption value="can0">can0</IonSelectOption>
                  <IonSelectOption value="can0+can1">can0+can1</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Lidar
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.lidar_version} {...props("lidar_version")}>
                  <IonSelectOption value="lidar via can1">lidar via can1</IonSelectOption>
                  <IonSelectOption value="lidar via uart">lidar via uart</IonSelectOption>
                  <IonSelectOption value="no lidar">no lidar</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Disable lidar
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.disable_lidar} {...props("disable_lidar")} disabled={tmpAquila.lidar_version === "no lidar"}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Central trigger
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.has_central_trigger} {...props("has_central_trigger")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Light controller firmware
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.light_controller_firmware} {...props("light_controller_firmware")}>
                  <IonSelectOption value="2021_jai1">2021_jai1 (with timing bug)</IonSelectOption>
                  <IonSelectOption value="2023_jai1">2023_jai1</IonSelectOption>
                  <IonSelectOption value="2021_jai2">2021_jai2 (with timing bug)</IonSelectOption>
                  <IonSelectOption value="2023_jai2">2023_jai2</IonSelectOption>
                  <IonSelectOption value="2024_arduino">2024_arduino</IonSelectOption>
                  <IonSelectOption value="2024_rust">2024_rust</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Ethernet connection
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.ethernet_interface_to_robot} {...props("ethernet_interface_to_robot")}>
                  {["usbeth0", "pcieeth0", "pcieeth1", "onboard_10GbE"].map((v) => <IonSelectOption value={v} key={v}>{v}</IonSelectOption>)}
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                NVME
              </IonCol>
              <IonCol>
                {tmpAquila?.computer?.nvme_gb || "?"} GB
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                RAM GB
              </IonCol>
              <IonCol>
                {tmpAquila?.computer?.ram_gb || "?"} GB
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                wide_view
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.has_wide_view} {...props("has_wide_view")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                fullres
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.has_fullres} {...props("has_fullres")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                camera is flipped
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.camera_is_flipped} {...props("camera_is_flipped")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Archived
              </IonCol>
              <IonCol>
                <IonToggle checked={tmpAquila.obsolete} {...props("obsolete")}/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonLabel>Description:</IonLabel>
              </IonCol>
              <IonCol>
                <IonInput value={tmpAquila.description} {...props("description")} placeholder="Add decription here"/>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Camera type
              </IonCol>
              <IonCol>
                <IonSelect value={tmpAquila.camera_type} {...props("camera_type")} >
                  <IonSelectOption value="jai1">jai1</IonSelectOption>
                  <IonSelectOption value="jai2">jai2</IonSelectOption>
                </IonSelect>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Camera MAC address
              </IonCol>
              <IonCol>
                {aquila.camera_mac}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Computer
              </IonCol>
              <IonCol>
                {aquila?.computer?.hardware_type}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                Jetpack Version
              </IonCol>
              <IonCol>
                {tmpAquila?.computer?.jetpack_version || "?"}
              </IonCol>
            </IonRow>

          </IonGrid>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};
